<template>
  <v-alert
    text
    color="primary"
  >
    <h3 class="text-h5">
      {{ $t('dtouch.menus', locale) }}
    </h3>
    <div>
      <!--Maecenas nec odio et ante tincidunt tempus. Sed mollis, eros et ultrices tempus, mauris ipsum aliquam libero, non adipiscing dolor urna a orci. Proin viverra, ligula sit amet ultrices semper, ligula arcu tristique sapien, a accumsan nisi mauris ac eros. Curabitur turpis.-->
    </div>


    <v-divider
      class="my-4 info"
      style="opacity: 0.22"
    ></v-divider>

    <v-row
      align="center"
      no-gutters
    >
      <v-col>
        <v-btn
          color="primary"
          outlined
          @click="handleAddMenu"
        >
        {{ $t('dtouch.addMenu', locale) }}
        </v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>
<script>
export default {
  props: {
    locale: {
      type: String,
      default: null,
    },
    onAddMenu: {
      type: Function,
      required: true,
    },
  },
  methods: {
    handleAddMenu () {
      this.onAddMenu(true, 'new', null)
    },
  },
}
</script>

